<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import Pagination from "@/router/layouts/table-pagination.vue";
import Datatable from "@/router/layouts/table-datatable.vue";
import permissionService from "../../../../helpers/permissionService";
import _ from "lodash";
// import $ from 'jquery';

export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Pagination,
    Datatable,
  },
  data() {
    let sortOrders = [];
    let columns = [
      { width: "50px", label: "No", name: "-" },
      { width: "auto", label: "Kode Kompetensi", name: "kode_kompetensi" },
      { width: "auto", label: "Kompetensi", name: "nama_kompetensi" },
      { width: "auto", label: "Level Proficiency", name: "-" },
      { width: "auto", label: "Tools", name: "-" },
      { width: "auto", label: "Jumlah Soal PG", name: "-" },
      { width: "auto", label: "Jumlah Soal BS", name: "-" },
      { width: "auto", label: "Jumlah Soal Menjodohkan", name: "-" },
      { width: "auto", label: "Jumlah Soal Esai", name: "-" },
      { width: "380px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
    ];

    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    const menu = "Kompetensi";

    return {
      title: menu,
      isWrite: localStorage.getItem("isWrite"),
      isRead: localStorage.getItem("isRead"),
      isWriteSimulasi: false,
      isReadSimulasi: false,
      isWriteSetup: false,
      isReadSetup: false,
      isWriteDisable: false,
      isReadDisable: false,
      items: [
        {
          text: "Master Kompetensi",
          href: "/",
        },
        {
          text: "Kompetensi",
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      user_name: localStorage.session_name,
      pathPhoto: localStorage.session_path_photo,
      pathPhotoFull:
        process.env.VUE_APP_BACKEND_URL + localStorage.session_path_photo,
      columns: columns,
      sortKey: "nama_kompetensi",
      sortOrders: sortOrders,
      options: [],
      optionFilterKodeKompetensi: [],
      filterKodeKompetensi: [],
      filterKompetensi: [],
      modalImport: false,
      modalTambah: false,
      loadingTable: true,
      listLevelKompetensi: [],
      tableData: [],
      tableFilter: {
        status: "1",
        search: "",
        per_page: 10,
        page: 0,
        kompetensi: "",
        level: "",
        tools: "",
        kode_kompetensi: "",
        column: "",
        dir: "desc",
      },
      pagination: {
        total: "",
        from: "",
        to: "",
        page: "",
        per_page: "",
        links: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
      },
      idLevelKompetensi: "",
      namaKompetensi: "",
      kodeKompetensi: "",
    };
  },
  async mounted() {
    this.cekPermission("simulasi", "Simulasi Tes");
    this.cekPermission("setup", "Setup Soal  + All Menu");
    this.cekPermission("disable", "Disable");

    this.getKompetensi();
    this.getKompetensiFilter();
    this.getKodeKompetensiFilter();
    this.getLevelKompetensi();

    this.filterKodeKompetensi = _.debounce(
      this.searchKodeKompetensi.bind(this),
      500
    );
    this.filterKompetensi = _.debounce(this.searchKompetensi.bind(this), 500);
  },
  methods: {
    async cekPermission(type, permission) {
      const data = await permissionService.getHakAkses(permission);
      if (type == "simulasi") {
        this.isWriteSimulasi = data.is_write;
        this.isReadSimulasi = data.is_read;
      } else if (type == "setup") {
        this.isWriteSetup = data.is_write;
        this.isReadSetup = data.is_read;
      } else if (type == "disable") {
        this.isWriteDisable = data.is_write;
        this.isReadDisable = data.is_read;
      }
    },
    async searchKompetensi(search, loading) {
      if (search.length) {
        loading(true);
        await this.getKompetensiFilter(search, loading);
      }
    },
    async getKompetensiFilter(search = "", loading) {
      await axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/kompetensi/all",
          {
            params: {
              search: search,
              limit: 10,
            },
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
          }
        )
        .then((response) => {
          this.options = response.data.data;
          if (loading) loading(false);
        });
    },
    async searchKodeKompetensi(search, loading) {
      if (search.length) {
        loading(true);
        await this.getKodeKompetensiFilter(search, loading);
      }
    },
    async getKodeKompetensiFilter(search = "", loading) {
      await axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/kompetensi/all",
          {
            params: {
              search: search,
              limit: 10,
              col: "kode_kompetensi",
            },
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
          }
        )
        .then((response) => {
          this.optionFilterKodeKompetensi = response.data.data;
          if (loading) loading(false);
        });
    },
    async getKompetensi() {
      this.loadingTable = true;
      const data = await axios({
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/kompetensi",
        params: this.tableFilter,
        headers: {
          Authorization: "Bearer " + localStorage.access_token,
        },
      })
        .then((response) => response.data)
        .catch((error) => {
          console.log(error);
          return false;
        });

      if (data) {
        this.tableData = data.data.data;
        this.pagination.total = data.data.total;
        this.pagination.from = data.data.from;
        this.pagination.to = data.data.to;
        this.pagination.links = data.data.links;
        this.pagination.lastPageUrl = data.last_page_url;
        this.pagination.nextPageUrl = data.next_page_url;
        this.pagination.prevPageUrl = data.prev_page_url;
        this.pagination.per_page = this.tableFilter.per_page;
        this.pagination.page = this.tableFilter.page;
        // console.log(this.tableData);
      }
      this.loadingTable = false;
    },
    toPage(url) {
      let link = new URLSearchParams(url);
      this.tableFilter.page = link.get("page");
      // this.tableFilter.per_page = link.get('per_page');
      this.getKompetensi();
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      this.tableFilter.column = key;
      this.tableFilter.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getKompetensi();
    },
    getLevelKompetensi() {
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION + "master/level-kompetensi",
          {
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
          }
        )
        .then((response) => {
          this.listLevelKompetensi = response.data.data;
          console.log(this.listLevelKompetensi);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    isAbleSimulasi(rowForm) {
      if (rowForm.idlevel_kompetensi == 1) {
        return rowForm.total_soal_pg > 0;
      } else if (rowForm.idlevel_kompetensi == 2) {
        return (
          rowForm.total_soal_pg > 0 &&
          rowForm.total_soal_bs > 0 &&
          rowForm.total_soal_menjodohkan > 0
        );
      } else if (rowForm.idlevel_kompetensi == 3) {
        return rowForm.total_soal_pg > 0 && rowForm.total_soal_bs > 0;
      } else if (rowForm.idlevel_kompetensi == 4) {
        return rowForm.total_soal_text > 0;
      }
      return false;
    },
    async simulasiTest(idkompetensi) {
      console.log(idkompetensi);
      await Swal.fire({
        icon: "warning",
        title: "Apakah Anda yakin melakukan simulasi tes kompetensi ini ?",
        text: "Data simulasi sebelumnya akan dihapus",
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
      }).then(async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Loading...",
            allowOutsideClick: false,
            showConfirmButton: false,
          });

          // get ipaddress
          // const ipAddress = await $.getJSON(
          //   'https://api.ipify.org?format=json',
          //   function (data) {
          //     return data;
          //   },
          //   function () {
          //     return {};
          //   }
          // );

          // request token sso
          const tokenSSO = await axios({
            method: "get",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "master/kompetensi/" +
              idkompetensi +
              "/simulasi",
            params: {
              ipAddress: "8.8.8.8",
              browser: "Chrome",
            },
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
          })
            .then((response) => response.data.data)
            .catch((err) => {
              console.log(err);
              return false;
            });

          if (tokenSSO) {
            this.$router.push({
              name: "dashboard-assesmen-hc",
              query: {
                sso: tokenSSO,
                isSimulation: true,
              },
            });
          }
        }
      });
      Swal.close();
    },
    addKompetensi() {
      this.namaKompetensi = "";
      this.idLevelKompetensi = 1;
      this.kodeKompetensi = "";
      this.modalTambah = true;
    },
    async postKategoriSoal() {
      Swal.fire({
        icon: "warning",
        iconHtml: `<i class="fa fa-spinner fa-spin"></i>`,
        title: "Loading...",
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      const body = {
        nama_kompetensi: this.namaKompetensi,
        id_level_kompetensi: this.idLevelKompetensi,
        kode_kompetensi: this.kodeKompetensi,
        created_by: "",
        updated_by: "",
      };
      await axios
        .post(
          process.env.VUE_APP_BACKEND_URL_VERSION + "master/kompetensi",
          body,
          {
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
          }
        )
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Data berhasil disimpan",
            timer: 1500,
            showConfirmButton: false,
          });
          console.log(response);
          this.modalTambah = false;
        })
        .catch(async (error) => {
          console.log(error?.response?.data?.data);
          const errMessage =
            (await error?.response?.data?.data) == "Data found"
              ? "Kompetensi tersebut sudah ada"
              : "";
          Swal.fire({
            icon: "error",
            title: "Data gagal disimpan",
            text: errMessage,
            timer: 1500,
            showConfirmButton: false,
          });
          console.error(error);
          this.modalTambah = false;
        });
      this.getKompetensi();
    },
    disableKompetensi(data) {
      const body = {
        nama_kompetensi: data.nama_kompetensi,
        level_kompetensi_idlevel_kompetensi: data.idlevel_kompetensi,
        active_flag: data.active_flag === 1 ? 0 : 1,
        updated_by: "",
      };
      axios
        .put(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "master/kompetensi/" +
            data.idkompetensi,
          body,
          {
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
          }
        )
        .then((response) => {
          if (data.active_flag === 0) {
            Swal.fire({
              icon: "success",
              title: "Data berhasil enable",
              timer: 1500,
              showConfirmButton: false,
            });
          } else if (data.active_flag === 1) {
            Swal.fire({
              icon: "success",
              title: "Data berhasil disable",
              timer: 1500,
              showConfirmButton: false,
            });
          }
          console.log(response);
          this.getKompetensi();
        })
        .catch((error) => {
          if (data.active_flag === 0) {
            Swal.fire({
              icon: "error",
              title: "Data gagal enable",
              timer: 1500,
              showConfirmButton: false,
            });
          } else if (data.active_flag === 1) {
            Swal.fire({
              icon: "error",
              title: "Data gagal disable",
              timer: 1500,
              showConfirmButton: false,
            });
          }
          console.error(error);
        });
    },
    exportData() {
      let params = new URLSearchParams(this.tableFilter).toString();
      window.open(
        process.env.VUE_APP_BACKEND_URL_VERSION +
          "export-excel/kompetensi?" +
          params
      );
    },
    exportDataSoal(idkompetensi) {
      let params = new URLSearchParams({
        idkompetensi: idkompetensi,
      }).toString();
      window.open(
        process.env.VUE_APP_BACKEND_URL_VERSION +
          "export-excel/kompetensi-soal?" +
          params,
        "_blank"
      );
    },
    downloadTemplate() {
      window.open(
        process.env.VUE_APP_BACKEND_URL_VERSION +
          "download-template/kompetensi",
        "_blank"
      );
    },
    importData: async function () {
      this.importFile = await this.$refs.file.files[0];

      Swal.fire({
        icon: "warning",
        title: "Mohon tunggu",
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      await axios
        .post(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "master/kompetensi/import-excel",
          {
            file: this.importFile,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Create data berhasil",
            timer: 1000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          });
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "warning",
            title: "Terjadi Kesalahan",
            timer: 3000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          });
        });

      this.modalImport = false;
      this.$refs.file.files = null;
      await this.getKompetensi();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between gap-2 mb-2">
              <div class="card-title">Daftar Kompetensi</div>
              <div>
                <!-- <button
                  class="btn btn-success btn-sm mx-2"
                  @click="modalImport = true"
                  v-if="isWrite == 1"
                >
                  <i class="fa fa-file-excel me-1"></i> Import Excel
                </button> -->
                <div class="d-flex justify-content-end gap-2">
                  <button
                    class="btn btn-success btn-sm"
                    @click="modalImport = true"
                    v-if="isWrite == 1"
                  >
                    <i class="fa fa-file-excel me-1"></i> Import Kompetensi
                  </button>
                  <button class="btn btn-warning btn-sm" @click="exportData()">
                    <i class="fa fa-file-excel me-1"></i> Export Kompetensi
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    @click="addKompetensi()"
                    v-if="isWrite == 1"
                  >
                    <i class="fa fa-plus me-1"></i>
                    Tambah
                  </button>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="form-group col-lg">
                <label for="filterKompetensi">Kode Kompetensi</label>
                <v-select
                  id="parent_menu"
                  class="style-chooser"
                  :options="optionFilterKodeKompetensi"
                  :reduce="(item) => item.kode_kompetensi"
                  label="kode_kompetensi"
                  v-model="tableFilter.kode_kompetensi"
                  placeholder="Filter Kode Kompetensi"
                  @search="filterKodeKompetensi"
                  @update:modelValue="getKompetensi()"
                >
                </v-select>
              </div>
              <div class="form-group col-lg">
                <label for="filterKompetensi">Kompetensi</label>
                <v-select
                  id="parent_menu"
                  class="style-chooser"
                  :options="options"
                  :reduce="(item) => item.nama_kompetensi"
                  label="nama_kompetensi"
                  v-model="tableFilter.kompetensi"
                  placeholder="Filter Kompetensi"
                  @search="filterKompetensi"
                  @update:modelValue="getKompetensi()"
                >
                </v-select>
              </div>
              <div class="form-group col-lg">
                <label for="filterLevel">Level</label>
                <select
                  id="filterLevel"
                  class="form-control"
                  v-model="tableFilter.level"
                  @update:modelValue="getKompetensi()"
                >
                  <option value="">Semua Level</option>
                  <option
                    v-for="level in listLevelKompetensi"
                    :key="level.idlevel_kompetensi"
                    :value="level.nama_level"
                  >
                    {{ level.nama_level }}
                  </option>
                </select>
              </div>
              <div class="form-group col-lg">
                <label for="filterTools">Tools</label>
                <select
                  class="form-control"
                  id="filterTools"
                  v-model="tableFilter.tools"
                  @update:modelValue="getKompetensi()"
                >
                  <option value="">Semua</option>
                  <option value="Lengkap">Lengkap</option>
                  <option value="Tidak Lengkap">Tidak Lengkap</option>
                </select>
              </div>
              <div class="form-group col-lg">
                <label for="statusTable">Status</label>
                <select
                  class="form-control"
                  id="statusTable"
                  v-model="tableFilter.status"
                  @update:modelValue="getKompetensi()"
                >
                  <option value="1">ENABLE</option>
                  <option value="0">DISABLE</option>
                </select>
              </div>
              <!-- <div class="form-group col-lg d-flex align-items-end">
                <div>
                  <button class="btn btn-primary" @click="getKompetensi()">
                    <i class="fa fa-search me-1"></i>Filter
                  </button>
                </div>
              </div> -->
            </div>
            <hr />
            <table class="mb-2">
              <tr>
                <td>Show</td>
                <td>
                  <select
                    class="form-control form-control-sm"
                    id="showpaginatetable"
                    v-model="tableFilter.per_page"
                    @change="getKompetensi()"
                  >
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                  </select>
                </td>
                <td>Entries</td>
              </tr>
            </table>
            <datatable
              :columns="columns"
              :sortKey="sortKey"
              :sortOrders="sortOrders"
              @sort="sortBy"
            >
              <tbody>
                <tr v-if="loadingTable">
                  <td colspan="99" class="text-center">
                    <i class="fas fa-spinner fa-spin"></i>
                    Loading
                  </td>
                </tr>
                <tr v-else-if="tableData.length < 1">
                  <td colspan="99" class="text-center">Data Empty</td>
                </tr>
                <tr v-else v-for="(rowForm, index) in tableData" :key="index">
                  <td class="text-center">{{ pagination.from + index }}</td>
                  <td class="text-center">
                    {{ rowForm.kode_kompetensi || "-" }}
                  </td>
                  <td>{{ rowForm.nama_kompetensi }}</td>
                  <td>{{ rowForm.nama_level }}</td>
                  <td>
                    <span v-if="rowForm.idlevel_kompetensi == 1">
                      <span v-if="rowForm.total_soal_pg > 0">Lengkap</span>
                      <span v-else>Tidak Lengkap</span>
                    </span>
                    <span v-else-if="rowForm.idlevel_kompetensi == 2">
                      <span
                        v-if="
                          rowForm.total_soal_pg > 0 &&
                          rowForm.total_soal_bs > 0 &&
                          rowForm.total_soal_menjodohkan > 0
                        "
                      >
                        Lengkap
                      </span>
                      <span v-else>Tidak Lengkap</span>
                    </span>
                    <span v-else-if="rowForm.idlevel_kompetensi == 3">
                      <span
                        v-if="
                          rowForm.total_soal_pg > 0 && rowForm.total_soal_bs > 0
                        "
                      >
                        Lengkap
                      </span>
                      <span v-else>Tidak Lengkap</span>
                    </span>
                    <span v-else-if="rowForm.idlevel_kompetensi == 4">
                      <span v-if="rowForm.total_soal_text > 0"> Lengkap </span>
                      <span v-else>Tidak Lengkap</span>
                    </span>
                  </td>
                  <td class="text-center">{{ rowForm.total_soal_pg }}</td>
                  <td class="text-center">{{ rowForm.total_soal_bs }}</td>
                  <td class="text-center">
                    {{ rowForm.total_soal_menjodohkan }}
                  </td>
                  <td class="text-center">{{ rowForm.total_soal_text }}</td>
                  <td class="text-center">
                    <div class="btn-group">
                      <button
                        class="btn btn-success btn-sm"
                        @click="exportDataSoal(rowForm.idkompetensi)"
                      >
                        <i class="fa fa-file-excel"></i> Export Soal
                      </button>
                      <div class="btn-group">
                        <button
                          class="btn btn-warning btn-sm"
                          :disabled="!isAbleSimulasi(rowForm)"
                          @click="simulasiTest(rowForm.idkompetensi)"
                          v-if="isWriteSimulasi == 1"
                        >
                          <i class="fa fa-desktop"></i> Simulasi Tes
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary dropdown-toggle btn-sm"
                          data-bs-toggle="dropdown"
                          v-if="isReadSetup == 1 || isWriteSetup == 1"
                        >
                          <i class="fas fa-edit"></i> Setup Soal
                        </button>
                        <div class="dropdown-menu">
                          <router-link
                            :to="`/master-kompetensi/kompetensi/${rowForm.idkompetensi}/soal-pilihan-ganda`"
                            v-if="rowForm.idlevel_kompetensi !== 4"
                          >
                            <a class="dropdown-item" href="#">
                              Pilihan Ganda
                            </a>
                          </router-link>
                          <router-link
                            :to="`/master-kompetensi/kompetensi/${rowForm.idkompetensi}/soal-benar-salah`"
                            v-if="
                              rowForm.idlevel_kompetensi === 2 ||
                              rowForm.idlevel_kompetensi === 3
                            "
                          >
                            <a class="dropdown-item" href="#"> Benar salah </a>
                          </router-link>
                          <router-link
                            :to="`/master-kompetensi/kompetensi/${rowForm.idkompetensi}/soal-jodoh`"
                            v-if="rowForm.idlevel_kompetensi === 2"
                          >
                            <a class="dropdown-item" href="#">Menjodohkan</a>
                          </router-link>
                          <router-link
                            :to="`/master-kompetensi/kompetensi/${rowForm.idkompetensi}/soal-text`"
                            v-if="rowForm.idlevel_kompetensi === 4"
                          >
                            <a class="dropdown-item" href="#">Esai</a>
                          </router-link>
                        </div>
                      </div>
                      <button
                        v-if="rowForm.active_flag === 1 && isWriteDisable == 1"
                        type="button"
                        class="btn btn-danger btn-sm"
                        @click="disableKompetensi(rowForm)"
                      >
                        <i class="fas fa-ban"></i> Disable
                      </button>
                      <button
                        v-if="rowForm.active_flag === 0 && isWriteDisable == 1"
                        type="button"
                        class="btn btn-success btn-sm"
                        @click="disableKompetensi(rowForm)"
                      >
                        <i class="fas fa-check"></i> Enable
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </datatable>
            <Pagination :pagination="pagination" @to-page="toPage"></Pagination>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>

  <b-modal v-model="modalTambah" title="Tambah Kompetensi" hide-footer>
    <b-form class="p-0" @submit.prevent="postKategoriSoal()">
      <b-form-group label="Kode Kompetensi" label-for="kodeKompetensi">
        <b-form-input
          id="kodeKompetensi"
          v-model="kodeKompetensi"
          placeholder="Kode Kompetensi..."
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Kompetensi" label-for="kompetensi">
        <b-form-input
          id="kompetensi"
          v-model="namaKompetensi"
          placeholder="Nama Kompetensi..."
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Level Proficiency" label-for="level_proficiency">
        <select
          class="form-select"
          id="level_proficiency"
          v-model="idLevelKompetensi"
        >
          <option value="">-- Pilih Level Proficiency --</option>
          <option
            v-for="list in listLevelKompetensi"
            :key="list.idlevel_kompetensi"
            :value="list.idlevel_kompetensi"
          >
            {{ list.nama_level }}
          </option>
        </select>
      </b-form-group>
      <button type="submit" class="btn btn-primary float-end">Simpan</button>
    </b-form>
  </b-modal>
  <b-modal
    v-model="modalImport"
    title="Import Kompetensi"
    size="md"
    hide-footer
  >
    <div class="row">
      <div class="col-12 mb-2">
        <button class="btn btn-success btn-sm" @click="downloadTemplate()">
          <i class="fa fa-download me-1"></i> Download Template
        </button>
      </div>
      <div class="col-12 mb-2">
        <div class="form-group">
          <label for="file_import_excel">File Excel</label>
          <input
            type="file"
            id="file_import_excel"
            class="form-control"
            ref="file"
            accept=".xlsx,.xls"
            mime
          />
        </div>
      </div>
      <div class="col-12">
        <button class="btn btn-primary btn-sm float-end" @click="importData()">
          <i class="fa fa-save me-1"></i> Save
        </button>
      </div>
    </div>
  </b-modal>
</template>

<style>
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  border: none;
  color: #394066;
  /* text-transform: lowercase; */
}
.style-chooser .vs__dropdown-toggle {
  border: 1px solid #ced4da;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #394066;
}
</style>
